export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'halcom, system-ui, sans-serif',
    heading: 'halcom, system-ui, sans-serif',
    monospace: '"Roboto Mono", monospace',
    primary: 'halcom, system-ui, sans-serif',
    secondary: 'halcom, system-ui, sans-serif',
    accent: 'merriweather system-ui sans-serif',
  },
  fontSizes: [
    '0.9rem', // 9px | 0
    '1.0rem', // 10px | 1
    '1.1rem', // 11px | 2
    '1.2rem', // 12px | 3
    '1.3rem', // 13px | 4
    '1.4rem', // 14px | 5
    '1.5rem', // 15px | 6
    '1.6rem', // 16px | 7
    '1.7rem', // 17px | 8
    '1.8rem', // 18px | 9
    '1.9rem', // 19px | 10
    '2.0rem', // 20px | 11
    '2.2rem', // 22px | 12
    '2.4rem', // 24px | 13
    '2.5rem', // 25px | 14
    '2.6rem', // 26px | 15
    '3.0rem', // 30px | 16
    '3.1rem', // 31px | 17
    '3.4rem', // 34px | 18
    '3.5rem', // 35px | 19
    '4.5rem', // 45px | 20
    '4.8rem', // 48px | 21
  ],
  fontWeights: {
    body: 400,
    medium: 500,
    bold: 700,
    extrabold: 800,
  },
  lineHeights: {
    heading: 1.05,
    body: 1.25,
  },
  colors: {
    text: '#202124',
    background: '#fff',
    altBackground: '#E0EEEF',
    primary: '#215732',
    secondary: '#38A169',
    accent: '#7C8034',
    muted: '#F0FFF4',
    errorDark: '#B30909',
    oldBrick: '#9f1c28',
    chatelle: '#b5afc2',
    darkTan: '#69102c',
    alizarinCrimson: '#CF2029',
    jaffa: '#f08f42',
    stiletto: '#a12a3f',
    canary: '#e6fc5c',
    white: '#fff',
    offWhite: '#fff4e3',
    skyBlue: '#71c3ef',
    almond: '#ecd6c4',
    tuatara: '#242422',
    lightGrey: '#D8D8D8',
    lightPurple: '#e2dcef',
    discountGreen: '#1C8A00',
  },
  styles: {
    root: {
      variant: 'text.body',
      a: { color: 'inherit', textDecoration: 'none' },
      fontFamily: 'body',
      fontWeight: 400,
      button: {
        padding: '.5rem 1rem !important',
        borderRadius: '18px',
        fontSize: [2, 3, 4],
        fontWeight: 800,
        letterSpacing: 0,
        margin: 0,
      },
    },
    div: {
      backgroundColor: 'colors.splashBackground',
    },
  },
  badges: {
    primary: {
      backgroundColor: 'rgba(180, 152, 240, 0.3)',
      color: 'alizarinCrimson',
      borderRadius: '30px',
      padding: ['.3rem .5rem', null, '.3rem 1rem'],
      fontSize: [1, 2, 3],
      fontWeight: 700,
    },
  },
  forms: {
    radio: {
      subscription: {
        display: 'none',
        color: 'canary',
        '&checked:': { color: 'chatelle' },
        '&::before': {
          content: '""',
          display: 'inline-block',
          height: '25px',
          width: '25px',
          backgroundColor: 'black',
          borderRadius: '50%',
        },
        '&::after': {
          content: '""',
          display: 'inline-block',
          width: '25px',
          height: '25px',
          backgroundColor: 'black',
          borderRadius: '50%',
        },
      },
    },
    checkbox: {
      '& input:focus': {
        outline: 'none',
      },
      '& path': {
        fill: 'alizarinCrimson',
      },
    },
  },
  buttons: {
    journeySlderButtons: {
      borderRadius: '2.5rem',
      backgroundColor: 'darkTan',
      minWidth: ['4rem', '4rem', '5rem'],
      minHeight: ['4rem', '4rem', '5rem'],
      maxWidth: ['4rem', '4rem', '5rem'],
      maxHeight: ['4rem', '4rem', '5rem'],
      color: '#fff',
      borderColor: 'transparent',
      alignItems: 'center',
      path: {
        stroke: '#fff',
      },
    },
    longJourney: {
      color: 'darkTan',
      fontSize: '1.4rem',
      minWidth: '14rem',
      height: '3.5rem',
      p: '0.5rem 1rem',
      borderRadius: '10rem',
      borderColor: 'darkTan',
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      alignItems: 'center',
      textTransform: 'none',
      fontWeight: 'normal',
      svg: {
        height: '1.5rem',
      },
      path: {
        stroke: 'darkTan',
      },
    },
    ctaHighlight: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      textTransform: 'uppercase',
      fontSize: 9,
      fontWeight: 'bold',
      color: 'alizarinCrimson',
      py: '15px !important',
      px: '30px !important',
      width: 'max-content',
      border: '1px solid transparent',
      borderColor: 'alizarinCrimson',
      backgroundColor: 'canary',
      borderRadius: '10px',
      transition: 'all 0.3s ease-in-out',
      ':hover': {
        color: ['alizarinCrimson', 'alizarinCrimson', 'canary'],
        borderColor: ['alizarinCrimson', 'alizarinCrimson', 'canary'],
        backgroundColor: ['canary', 'canary', 'alizarinCrimson'],
        boxShadow: ['none', 'none', '5px 5px 0 var(--theme-ui-colors-canary)'],
        'svg path': {
          stroke: ['alizarinCrimson', 'alizarinCrimson', 'canary'],
        },
      },
      svg: {
        height: '15px',
        marginLeft: '10px',
        path: {
          transition: 'all 0.3s ease-in-out',
        },
      },
    },
    widgetButton: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      textTransform: 'uppercase',
      fontSize: 9,
      fontWeight: 'bold',
      color: 'canary',
      py: '15px !important',
      px: '30px !important',
      width: 'max-content',
      border: '1px solid transparent',
      borderRadius: '10px',
      transition: 'all 0.3s ease-in-out',
      svg: {
        height: '15px',
        marginLeft: '10px',
        path: {
          transition: 'all 0.3s ease-in-out',
          stroke: 'canary',
        },
      },
    },
    primary: {
      backgroundColor: 'transparent',
      color: 'alizarinCrimson',
      border: '1px solid #e3232a',
      cursor: "url('/images/blood.svg') 10 0, pointer",
      fontFamily: 'primary',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
    secondary: {
      backgroundColor: 'alizarinCrimson',
      color: 'canary',
      border: '1px solid #e6fc5c',
      fontWeight: 400,
      margin: 0,
      padding: '.5rem 1rem',
      borderRadius: '1.8rem',
    },
    tertiary: {
      backgroundColor: 'canary',
      color: 'alizarinCrimson',
      border: '1px solid #e3232a',
      fontWeight: 400,
      padding: '.5rem 1rem',
      borderRadius: '1.8rem',
      fontSize: [4, 6, 8],
    },
    flowActive: {
      backgroundColor: 'alizarinCrimson',
      color: 'canary',
      textTransform: 'uppercase',
      width: ['123px'],
      border: '1px solid alizarinCrimson',
      fontWeight: 400,
      margin: 0,
      padding: '.5rem 1rem',
      borderRadius: '10px',
    },
    flowInactive: {
      backgroundColor: 'transparent',
      color: 'alizarinCrimson',
      textTransform: 'uppercase',
      width: ['123px'],
      border: 'none',
      fontWeight: 400,
      margin: 0,
      padding: '.5rem 1rem',
      borderRadius: '10px',
    },
    showMeMore: {
      color: `alizarinCrimson`,
      border: `1px solid #b5afc2`,
      borderRadius: '15px',
      textTransform: 'uppercase',
      backgroundColor: 'canary',
      fontSize: [4, 6, 8],
      fontWeight: 400,
      display: 'flex',
      py: '14px',
      alignItems: 'center',
      justifyContent: 'center',
      width: ['270px', '300px', '369px'],
    },
    story: {
      color: `alizarinCrimson`,
      border: `1px solid #b5afc2`,
      borderRadius: '15px',
      textTransform: 'uppercase',
      backgroundColor: 'canary',
      fontSize: [4, 6, 8],
      fontWeight: 400,
      display: 'flex',
      py: '14px',
      px: 0,
      alignItems: 'center',
      justifyContent: ['center', 'center', 'center'],
      width: ['120px', '230px', '309px'],
    },
    accountSection: {
      cursor: "url('/images/blood.svg') 10 0, pointer",
      fontFamily: 'secondary',
      backgroundColor: 'transparent',
      color: 'alizarinCrimson',
      textTransform: 'uppercase',
      borderRadius: '0px',
    },
    product: {
      cursor: "url('/images/blood.svg') 10 0, pointer",
      fontFamily: 'secondary',
      height: '3.9rem',
      width: ['18.2rem'],
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      fontWeight: 700,
      backgroundColor: 'canary',
      color: 'alizarinCrimson',
      borderRadius: '1.8rem',
      '&:active': {
        backgroundColor: 'alizarinCrimson',
        color: 'canary',
      },
    },
    oneTime: {
      cursor: "url('/images/blood.svg') 10 0, pointer",
      fontFamily: 'secondary',
      fontSize: [4, 6, 8],
      height: '80%',
      minWidth: ['11.2rem', '15.2rem', '15.8rem'],
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      fontWeight: 600,
      backgroundColor: 'canary',
      color: 'alizarinCrimson',
      borderRadius: '10px !important',
      '&:active': {
        backgroundColor: 'alizarinCrimson',
        color: 'canary',
      },
    },
    fixedPdp: {
      cursor: "url('/images/blood.svg') 10 0, pointer",
      fontFamily: 'secondary',
      fontSize: [4, 6, 8],
      height: '80%',
      width: '290px',
      minWidth: ['270px'],
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      fontWeight: 600,
      backgroundColor: 'canary',
      color: 'alizarinCrimson',
      borderRadius: '10px !important',
      '&:active': {
        backgroundColor: 'alizarinCrimson',
        color: 'canary',
      },
    },
    subscription: {
      cursor: "url('/images/blood.svg') 10 0, pointer",
      fontFamily: 'secondary',
      fontSize: [4, 6, 8],
      width: ['11.2rem', '15.2rem', '80%'],
      maxWidth: ['11.2rem', '15.2rem', '180px'],
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      fontWeight: 600,
      backgroundColor: 'canary',
      color: 'alizarinCrimson',
      borderRadius: '10px !important',
      '&:active': {
        backgroundColor: 'alizarinCrimson',
        color: 'canary',
      },
    },
    quickSub: {
      cursor: "url('/images/blood.svg') 10 0, pointer",
      fontFamily: 'secondary',
      fontSize: [5],
      width: ['80%', '15.2rem', '85%'],
      maxWidth: ['none', 'none', '135px'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      fontWeight: 600,
      backgroundColor: 'canary',
      color: 'alizarinCrimson',
      borderRadius: '10px !important',
      '&:active': {
        backgroundColor: 'alizarinCrimson',
        color: 'canary',
      },
    },
    donate: {
      cursor: "url('/images/blood.svg') 10 0, pointer",
      fontFamily: 'secondary',
      height: ['36px'],
      fontSize: [4, 6, '16px'],
      width: ['11.2rem', '15rem', '204px'],
      textTransform: 'uppercase',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontWeight: 600,
      backgroundColor: 'canary',
      color: 'chatelle',
      borderRadius: '13px !important',
      '&:active': {
        backgroundColor: 'alizarinCrimson',
        color: 'canary',
      },
    },
    removeBox: {
      cursor: "url('/images/blood.svg') 10 0, pointer",
      fontFamily: 'secondary',
      border: 'none',
      px: 0,
      fontSize: [4, 6, '16px'],
      textTransform: 'uppercase',
      fontWeight: 600,
      backgroundColor: 'none',
      color: 'alizarinCrimson',
    },
    paginate: {
      border: 'none',
      fontSize: [1, 2, 3],
      textTransform: 'none',
      '&:disabled': {
        color: 'chatelle',
        pointerEvents: 'none',
      },
    },
    cta: {
      cursor: "url('/images/blood.svg') 10 0, pointer",
      textTransform: 'uppercase',
      fontWeight: 500,
      backgroundColor: 'transparent',
      color: 'alizarinCrimson',
      border: '1px solid #e3232a',
      borderRadius: '18px',
      fontSize: [3, 8, 13],
      display: 'flex',
      width: ['21.1rem', '30.2rem', '40.3rem'],
      height: ['2.8rem', '3.8rem', '5.1rem'],
      justifyContent: 'center',
      alignItems: 'center',
    },
    ctaAlt: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100% !important',
      fontWeight: 'bold',
      fontSize: [2, 5],
      letterSpacing: '1px',
      color: 'canary',
      border: '1px solid black',
      backgroundColor: 'alizarinCrimson',
      transition: 'all 0.25s ease',
      '&:hover': {
        backgroundColor: 'canary',
        color: 'alizarinCrimson',
        '& > div > svg > path': {
          stroke: 'alizarinCrimson',
        },
      },
    },
    sampleBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '65%',
      height: '50px',
      fontWeight: 'bold',
      fontSize: [5, 5, 5],
      letterSpacing: '1px',
      color: 'canary',
      border: '1px solid alizarinCrimson',
      borderRadius: '25px',
      backgroundColor: 'alizarinCrimson',
      transition: 'all 0.25s ease',
      '&:hover': {
        backgroundColor: 'canary',
        color: 'alizarinCrimson',
        '& > div > svg > path': {
          stroke: 'alizarinCrimson',
        },
      },
    },
    bare: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '3.2rem',
      color: 'inherit',
      backgroundColor: 'transparent',
      padding: '0 !important',
      cursor: 'pointer',
    },
    clear: {
      padding: 'initial !important',
      color: 'inherit',
      backgroundColor: 'transparent',
      borderRadius: 0,
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
  },
  layout: {
    container: {
      margin: 'auto',
      maxWidth: 'calc(1600px + 8rem)',
      padding: ['0 1rem', null, '0 4rem'],
      overflow: 'hidden',
      width: '100%',
    },
    tablet: {
      margin: 'auto',
      maxWidth: 'calc(1600px)',
      padding: [0, null, null],
      overflow: 'hidden',
      width: '100%',
    },
    fullWidth: {
      padding: [0, null, null],
      overflow: 'hidden',
      width: '100%',
    },
  },
  responsive: {
    mobile: {
      display: ['block', 'none', 'none'],
    },
    mobileTablet: {
      display: ['block', 'block', 'none'],
    },
    tablet: {
      display: ['none', 'block', 'none'],
    },
    desktop: {
      display: ['none', 'none', 'block'],
    },
    tabletDesktop: {
      display: ['none', 'block', 'block'],
    },
  },
  text: {
    body: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 400,
      fontSize: [1, 2, 3],
      color: 'alizarinCrimson',
    },
    subheading: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 400,
      fontSize: [2, 10, 13],
      textTransform: 'uppercase',
      color: 'alizarinCrimson',
      '& > p': { margin: 0 },
    },
    altHeading: {
      fontFamily: 'merriweather',
      fontWeight: 700,
      fontStyle: 'italic',
      fontSize: [0, 4, 17],
    },
    small: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 400,
    },
    caps: {
      textTransform: 'uppercase',
    },
    formInput: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 400,
    },
    formLabel: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    formError: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 400,
      textTransform: 'uppercase',
      color: 'errorDark',
    },
    link: {
      color: 'inherit',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 400,
      textDecoration: 'none',
      letterSpacing: '2px',
      textTransform: 'uppercase',
    },
    menuLink: {
      color: 'alizarinCrimson',
      fontFamily: 'body',
      fontSize: ['1.3rem', '1.4rem', '1.5rem'],
      letterSpacing: '0.15rem',
      fontWeight: 800,
      textTransform: 'uppercase',
      cursor: "url('/images/blood.svg') 10 0, pointer",
      mx: ['2rem', '1rem', 'min(4rem, 3%)'],
      minWidth: 'fit-content',
    },
    homeLink: {
      fontFamily: 'body',
      lineHeight: 'heading',
      fontSize: [2, 3, 4],
      fontWeight: 800,
      color: 'canary',
      cursor: "url('/images/blood.svg') 10 0, pointer",
    },
    leadHeading: {
      textTransform: 'uppercase',
      color: 'alizarinCrimson',
      fontStyle: 'italic',
      fontWeight: 900,
      textDecoration: 'underline',
      textDecorationThickness: '12px',
      textDecorationColor: 'canary',
      textUnderlinePosition: 'unset',
      textUnderlineOffset: '-5px',
      textDecorationSkipInk: 'none',
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 700,
      '& > p': {
        margin: 0,
      },
    },
    h2: {
      color: 'alizarinCrimson',
      lineHeight: 'heading',
      fontFamily: 'heading',
      fontWeight: 800,
      fontSize: [9, 16, 20],
      textTransform: 'uppercase',
      '& > p': {
        margin: 0,
      },
    },
    h3: {
      color: 'alizarinCrimson',
      lineHeight: 'heading',
      fontFamily: 'heading',
      fontWeight: 400,
      textTransform: 'uppercase',
      fontSize: [2, 9, 13],
      '& > p': {
        margin: 0,
      },
    },
    h4: {
      fontFamily: 'body',
      lineHeight: 'heading',
      fontSize: [2, 3, 4],
      fontWeight: 800,
      color: 'canary',
      '& > p': {
        margin: 0,
      },
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 700,
      fontSize: 1,
      '& > p': {
        margin: 0,
      },
    },
    footerHeading: {
      textTransform: 'uppercase',
      color: 'canary',
      fontStyle: 'italic',
      fontSize: ['15px !important'],
      fontWeight: ['bold !important', 'bold', 'bold'],
      textDecoration: 'underline',
      textDecorationThickness: '5px',
      textDecorationColor: 'chatelle',
      textUnderlinePosition: 'unset',
      textUnderlineOffset: '-1px',
      textDecorationSkipInk: 'none',
      marginBottom: '2rem',
    },
    footerLink: {
      color: 'canary',
      fontWeight: 400,
      fontSize: [2, 6, 6],
      fontFamily: 'body',
      lineHeight: 'body',
      fontStyle: 'italic',
      mb: '1rem',
    },
    footerLegal: {
      color: 'chatelle',
      fontSize: [2, 3],
    },
    journeyHighlight: {
      fontSize: ['1.5rem', '1.5rem', '2rem'],
      fontWeight: 800,
      color: 'darkTan',
      maxWidth: '25ch',
      textAlign: 'center',
      mx: '1.5rem',
      mt: '1rem',
      textDecoration: 'underline',
      textDecorationThickness: '1.2rem',
      textDecorationColor: 'canary',
      textUnderlinePosition: 'unset',
      textUnderlineOffset: '-0.5rem',
      textDecorationSkipInk: 'none',
      lineHeight: '150%',
      transition: ' all 0.15s ease-in-out',
    },
  },
  input: {
    '&::placeholder': {
      color: 'alizarinCrimson',
    },
  },
  textarea: {
    '&::placeholder': {
      color: 'alizarinCrimson',
    },
  },
};
