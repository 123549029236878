/* eslint-disable react/display-name */
const React = require('react');
require('./static/fonts/fonts.scss');
require('~/assets/styles/scss/styles.scss');
require('~/assets/styles/yotpo-widget.css');
const { Global } = require('@emotion/react');
const { MessageProvider } = require('~/contexts/MessageContext');
const { SearchProvider } = require('~/contexts/SearchContext');
const { FilterProvider } = require('~/contexts/FilterContext');
const { CartProvider } = require('~/contexts/CartContext');
const { VariantProvider } = require('~/contexts/VariantContext');
const { default: AuthProvider } = require('~/contexts/AuthContext');
const { trackPageView } = require('~/utils/analytics');

exports.wrapPageElement = ({ element }) => {
  const ord = Math.random() * 10000000000000;

  return (
    <>
      <Global
        styles={() => ({
          html: {
            fontSize: '62.5%',
            scrollBehavior: 'smooth',
          },
          body: {
            fontSize: '1.6rem', // 16px
          },
        })}
      />
      <AuthProvider>
        {element}
        <img
          alt=""
          src={`https://trkn.us/pixel/conv/ppt=21967;g=sitewide;gid=51088;ord=${ord}`}
          height="0"
          width="0"
          border="0"
        />
      </AuthProvider>
    </>
  );
};

// eslint-disable-next-line import/prefer-default-export

exports.wrapRootElement = ({ element }) => (
  <React.Fragment>
    <CartProvider>
      <VariantProvider>
        <MessageProvider>
          <FilterProvider>
            <SearchProvider>{element}</SearchProvider>
          </FilterProvider>
        </MessageProvider>
      </VariantProvider>
    </CartProvider>
  </React.Fragment>
);

exports.onRouteUpdate = ({ location, prevLocation }) => {
  trackPageView();
};
