export const trackEmailCaptureEvent = ({ email, location }) => {
  if (typeof window === 'undefined' || !window.Northbeam) return;

  window.Northbeam.fireEmailCaptureEvent(email, location);
};

export const identify = (email) => {
  if (typeof window === 'undefined' || !window.Northbeam) return;

  window.Northbeam.identify('email', email);
};

export const trackPageView = () => {
  if (typeof window === 'undefined' || !window.Northbeam) return;
  window.Northbeam.trackPageView();
};

export const trackCustomEvent = (event, properties) => {
  if (typeof window === 'undefined' || !window.analytics) return;

  window.analytics.track(event, properties);
};
