import React, { useState } from 'react';

const VariantContext = React.createContext();

export const VariantProvider = ({ children }) => {
  const [variant, setVariant] = useState();
  return (
    <VariantContext.Provider value={[variant, setVariant]}>{children}</VariantContext.Provider>
  );
};

export default VariantContext;
