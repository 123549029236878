module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/build/repo/src/intl"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"manualLoad":false,"prodKey":"k9c9b8RG8KRHPPVjdYSJKj1ah00DHccX","devKey":"95dDia0tIuAENgZRRf3gIM7nTpwz6upa","host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"trackPage":false},
    },{
      plugin: require('../node_modules/@chordcommerce/gatsby-theme-autonomy/gatsby-browser.js'),
      options: {"plugins":[],"omsConfig":{"brandName":"august","storeId":"2","omsId":"67b3268e-1919-48e6-8b84-d7588343b214","tenantId":"460d1239-18ad-4ccc-9754-5773cfc66696","domain":"https://august-staging.assembly-api.com/","storeSlug":"august"},"contentfulConfig":{"spaceId":"imbw84wm8wpn","accessToken":"HyVOkkTepJg6m0NTF-pp9PilO9ZmaVlb4yFqQcxgc-g","host":"cdn.contentful.com","environment":"dev"},"segmentConfig":{"prodKey":"k9c9b8RG8KRHPPVjdYSJKj1ah00DHccX","devKey":"95dDia0tIuAENgZRRf3gIM7nTpwz6upa","host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":false,"trackPage":false},"intlConfig":{"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/build/repo/src/intl"},"siteMetadata":{"title":"August","siteUrl":"https://www.itsaugust.co","description":"Reimagining Periods.","social":{"facebook":"itsaugust","instagram":"itsaugust","pinterest":"itsaugust"},"instagram":"@itsaugust"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Merriweather"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
