import React, { useContext, useState } from 'react';

const SearchContext = React.createContext({
  search: '',
  setSearch: () => {},
  isSearching: false,
  setIsSearching: () => {},
});
export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  return (
    <SearchContext.Provider value={{ search, setSearch, isSearching, setIsSearching }}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContext;
