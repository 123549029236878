import React, { useState } from 'react';

const CartContext = React.createContext();

export const CartProvider = ({ children }) => {
  const [isCartOpen, setCartIsOpen] = useState(false);
  return (
    <CartContext.Provider value={[isCartOpen, setCartIsOpen]}>{children}</CartContext.Provider>
  );
};

export default CartContext;
