import React, { useEffect } from 'react';
import { StytchProvider } from '@stytch/nextjs';
import { useAuth, useUser } from '@chordcommerce/react-autonomy';
import { identify } from '~/utils/analytics';

const AuthProvider = ({ children }) => {
  const { serviceClient } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    if (!user?.data?.email) return;

    identify(user.data.email);
  }, [user]);

  return <StytchProvider stytch={serviceClient}>{children}</StytchProvider>;
};

export default AuthProvider;
